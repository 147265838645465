import {useMemo} from 'react';
import { useSelector } from 'react-redux';

import { selectSocialPanels } from 'hsi/slices/socialPanels';

import usePageTypes from './usePageTypes';
import useFlags from './useFlags';

import getFiltersConfig from 'hsi/utils/filters/getFiltersConfig';

import {SearchType} from 'hsi/types/shared';

export default function useGetFiltersConfig(
    searchType: SearchType,
    projectId: number,
    override?: boolean,
) {
    const pageTypes = usePageTypes(searchType === 'saved' || searchType === 'quick', projectId);

    const flags = useFlags();
    const socialPanels = useSelector(selectSocialPanels)

    return useMemo(
        () =>
            !override
                ? getFiltersConfig(searchType, pageTypes, projectId, flags, socialPanels )
                : {config: {}, allFiltersConfig: {}},
        [searchType, pageTypes, projectId, override, flags, socialPanels],
    );
}
